import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import "react-time-picker/dist/TimePicker.css";
import 'react-clock/dist/Clock.css';
import { DatePicker } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import ImageUploading from "react-images-uploading";
import * as Yup from "yup";
import {
  ADD_BLOG,
  EDIT_BLOG,
  GET_CATEGORY,
} from "../../../constant/apiEndPoint";
import {
  getRequest,
  patchFormRequest,
  postFormRequest,
} from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import {
  deleteMediaFromS3Bucket,
  uploadMediaToS3Bucket,
} from "../../../helper/awsHelper";
import ReactQuill from "react-quill";
import { htmlToText } from "html-to-text";

const AddBlogs = ({
  setAddEditModalShow,
  editBlogData,
  getTableBlog,
}) => {

  let [blogImg, setBlogImg] = useState([]);
  let [tempBlogImg, setTempBlogImg] = useState([]);

  // let [speakerImage, setSpeakerImage] = useState([]);
  // let [tempSpeakerImage, setTempSpeakerImage] = useState([]);

  useEffect(() => {
    // if loading existing aLink
    if (editBlogData?._id) {
      const tempBlogImgList = editBlogData.blogImage.map((item) => {
        return {
          key: item,
          data_url: process.env.REACT_APP_IMAGE_API_URL + item,
        };
      });

      setBlogImg([...tempBlogImgList]);
      setTempBlogImg([...editBlogData.blogImage]);

      // const tempSpeakerImageList = editBlogData.speakerImg.map((item) => {
      //   return {
      //     key: item,
      //     data_url: process.env.REACT_APP_IMAGE_API_URL + item,
      //   };
      // });

      // setSpeakerImage([...tempSpeakerImageList]);
      // setTempSpeakerImage([...editBlogData.speakerImg]);
    }
  }, [editBlogData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),
  });

  const initialValues = {
    name: editBlogData?.name || "",
    description: htmlToText(editBlogData?.description || ""),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values=====>", values);

      let forS3MediaArray = [];
      blogImg = await Promise.all(
        blogImg.map(async (image) => {
          if (image.file) {
            let nameArray = image.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray.length - 1]
              }`;
            // let waterMarkedUrl = await applyBottomAndCenterWatermark(
            //   image.file
            // );
            forS3MediaArray.push({ ...image, key: key });
            return { ...image, key: key };
          } else {
            tempBlogImg = tempBlogImg.filter(
              (item) => item !== image.key
            );
            return { ...image };
          }
        })
      );
      tempBlogImg.forEach(async (item) => {
        deleteMediaFromS3Bucket(item);
      });

      // speakerImage = await Promise.all(
      //   speakerImage.map(async (image) => {
      //     if (image.file) {
      //       let nameArray = image.file.name.split(".");
      //       let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray.length - 1]
      //         }`;
      //       // let waterMarkedUrl = await applyBottomAndCenterWatermark(
      //       //   image.file
      //       // );
      //       forS3MediaArray.push({ ...image, key: key });
      //       return { ...image, key: key };
      //     } else {
      //       tempSpeakerImage = tempSpeakerImage.filter(
      //         (item) => item !== image.key
      //       );
      //       return { ...image };
      //     }
      //   })
      // );
      // tempSpeakerImage.forEach(async (item) => {
      //   deleteMediaFromS3Bucket(item);
      // });

      await uploadMediaToS3Bucket([...forS3MediaArray]);
      values.blogImage = blogImg.map((item) => item.key);
      // values.speakerImg = speakerImage.map((item) => item.key);

      if (!editBlogData?._id) {
        try {
          const res = await postFormRequest(ADD_BLOG, values);
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableBlog();
        }
      } else {
        try {
          const res = await patchFormRequest(
            `${EDIT_BLOG}?id=${editBlogData?._id}`,
            values
          );
          console.log(res, "res");
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableBlog();
        }
      }
      setAddEditModalShow(false);
    },
  });

  const handleChangeBlogImgUpload = (e) => {
    setBlogImg(e);
  };
  // const handleChangeSpeakerImageUpload = (e) => {
  //   setSpeakerImage(e);
  // };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="card mb-0"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <div className="card-body p-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        placeholder="Name"
                        id="name"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label className="form-label d-block">Blog Image</label>
                      <ImageUploading
                        multiple={false}
                        value={blogImg}
                        onChange={handleChangeBlogImgUpload}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageUpdate,
                          onImageRemove,
                          dragProps,
                        }) => (
                          <div
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                              display: "flex",
                            }}
                            className="upload__image-wrapper"
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                margin: "0px0px 8px 0px",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                height: "80px",
                                width: "80px",
                                color: "#212121",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              +
                            </div>
                            <div
                              style={{
                                borderLeft: "1px solid #ccc",
                                padding: "0px 0px 0px 8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {imageList.map((image, index) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        position: "relative",
                                        margin: "0px 0px 8px 0px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        alt="closeIcon"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          borderRadius: "8px",
                                        }}
                                        src={image.data_url}
                                      ></img>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "2px",
                                          right: "2px",
                                          backgroundColor: "#fff",
                                          cursor: "pointer",
                                          borderRadius: "20px",
                                        }}
                                        onClick={() => {
                                          onImageRemove(index);
                                        }}
                                      >
                                        X
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                      {/* <span className="d-flex" style={{ columnGap: "10px" }}>
                        <input
                          id="blogImage"
                          type="file"
                          className="form-control"
                          onChange={handleChangeImageUpload}
                        />
                        {blogImg && blogImg.length > 0 && (
                          <div className="image-preview">
                            <img
                              src={blogImg[0].data_url}
                              alt="Blog Preview"
                              className="img-thumbnail-image"
                            />
                          </div>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Description
                      </label>
                      <ReactQuill
                        value={formik.values.description}
                        onChange={(value) =>
                          formik.setFieldValue("description", value)
                        }
                      />
                      {formik.touched.description &&
                        formik.errors.description ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-danger light"
                      onClick={() => {
                        formik.handleReset();
                        setAddEditModalShow(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary ms-2">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogs;
