import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import "react-time-picker/dist/TimePicker.css";
import 'react-clock/dist/Clock.css';
import * as Yup from "yup";
import {
  ADD_SUBSCRIBER,
  EDIT_SUBSCRIBER,
} from "../../../constant/apiEndPoint";
import {
  patchFormRequest,
  postFormRequest,
} from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

const AddSubscribers = ({
  setAddEditModalShow,
  editSubscriberData,
  getTableSubscriber,
}) => {

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required."),
  });

  const initialValues = {
    email: editSubscriberData?.email || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values=====>", values);

      if (!editSubscriberData?._id) {
        try {
          const res = await postFormRequest(ADD_SUBSCRIBER, values);
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableSubscriber();
        }
      } else {
        try {
          const res = await patchFormRequest(
            `${EDIT_SUBSCRIBER}?id=${editSubscriberData?._id}`,
            values
          );
          console.log(res, "res");
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableSubscriber();
        }
      }
      setAddEditModalShow(false);
    },
  });

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="card mb-0"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <div className="card-body p-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        placeholder="Email"
                        id="email"
                        type="email"
                        className="form-control"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div style={{ color: "red" }}>{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-danger light"
                      onClick={() => {
                        formik.handleReset();
                        setAddEditModalShow(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary ms-2">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubscribers;
