export const LOGIN_EP = "admin/signIn";
export const GET_COUNT = "admin/dashboard";

export const ADD_CATEGORY = "admin/category/createCategory";
export const GET_CATEGORY = "admin/category/getCategory";
export const EDIT_CATEGORY = "admin/category/updateCategory";
export const DELETE_CATEGORY = "admin/category/deleteCategory";

export const GET_COURSE = "admin/course/getCourse";
export const DELETE_COURSE = "admin/course/deleteCourse";
export const ADD_COURSE = "admin/course/createCourse";
export const EDIT_COURSE = "admin/course/updateCourse";

export const GET_BLOG = "admin/blog/getBlog";
export const DELETE_BLOG = "admin/blog/deleteBlog";
export const ADD_BLOG = "admin/blog/createBlog";
export const EDIT_BLOG = "admin/blog/updateBlog";

export const GET_SUBSCRIBER = "subscribeEmail/getSubscribeEmail";
export const DELETE_SUBSCRIBER = "subscribeEmail/deleteSubscribeEmail";
export const ADD_SUBSCRIBER = "subscribeEmail/createSubscribeEmail";
export const EDIT_SUBSCRIBER = "subscribeEmail/updateSubscribeEmail";

export const GET_STUDENT = "student/getStudent";
export const UPDATE_STUDENT = "student/updateStudent";
export const DELETE_STUDENT = "student/deleteStudent";

export const DELETE_CONTACT = "contactUs/deleteContactUs";
export const GET_CONTACT = "contactUs/getContactUs";

export const GET_USER = "admin/user/getUser";
export const DELETE_USER = "admin/user/deleteUser";
