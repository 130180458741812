import AWS from "aws-sdk";
const { Buffer } = require("buffer");

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  // accessKeyId: "AKIAXTORPSXAXBLKMXP7",
  // secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: "sageacedemy-media-uk" },
  region: "eu-west-2",
});

// Function to initiate a multipart upload
const createMultipartUpload = (params) => {
  return myBucket.createMultipartUpload(params).promise();
};

// Function to upload a part
const uploadPart = (params) => {
  return myBucket.uploadPart(params).promise();
};

// Function to complete a multipart upload
const completeMultipartUpload = (params) => {
  return myBucket.completeMultipartUpload(params).promise();
};

// Function to upload media using multipart upload
const uploadMediaMultipart = async (media) => {
  const params = {
    ACL: "private",
    Bucket: "sageacedemy-media-uk",
    Key: media.key,
    ContentType: media.file.type,
  };

  const multipartUpload = await createMultipartUpload(params);

  const uploadPromises = [];
  const chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
  const file = media.file;
  const totalParts = Math.ceil(file.size / chunkSize);

  for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
    const start = (partNumber - 1) * chunkSize;
    const end = Math.min(partNumber * chunkSize, file.size);
    const body = file.slice(start, end);

    const partParams = {
      Body: body,
      Bucket: "sageacedemy-media-uk",
      Key: media.key,
      PartNumber: partNumber,
      UploadId: multipartUpload.UploadId,
    };

    uploadPromises.push(uploadPart(partParams));
  }

  const uploadedParts = await Promise.all(uploadPromises);
  const parts = uploadedParts.map((part, index) => ({
    ETag: part.ETag,
    PartNumber: index + 1,
  }));

  const completeParams = {
    Bucket: "sageacedemy-media-uk",
    Key: media.key,
    UploadId: multipartUpload.UploadId,
    MultipartUpload: {
      Parts: parts,
    },
  };

  await completeMultipartUpload(completeParams);
};

export const uploadMediaToS3Bucket = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    const uploadPromises = mediaArray.map(async (media) => {
      let params = {
        ACL: "private",
        Bucket: "sageacedemy-media-uk",
        Key: media.key,
        ContentType: media.file.type,
      };

      if (media.waterMarkedUrl) {
        const base64Data = new Buffer.from(
          media.waterMarkedUrl.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        params.Body = base64Data;
        params.ContentEncoding = "base64";
      } else {
        if (media.file.size > 5 * 1024 * 1024) {
          // Use multipart upload for large files
          await uploadMediaMultipart(media);
          return;
        } else {
          params.Body = media.file;
        }
      }

      await myBucket.putObject(params).promise();
    });

    await Promise.all(uploadPromises);
    console.log("All uploads successful");
    return true; // All uploads successful
  } catch (error) {
    console.error("Error uploading media to S3:", error);
    return false; // Error occurred during upload
  }
};

export const deleteMediaFromS3Bucket = (key) => {
  const params = {
    Bucket: "sageacedemy-media-uk",
    Key: key,
  };

  myBucket.deleteObject(params, (err, data) => {
    if (err) {
      console.error(`Error deleting object ${key}:`, err);
    } else {
      console.log(`Object ${key} deleted successfully`);
    }
  });
};

// export const uploadMediaToS3Bucket = async (mediaArray) => {
//   console.log({ mediaArray })
//   if (mediaArray.length <= 0) {
//     return false;
//   }

//   try {
//     const uploadPromises = mediaArray.map(async (media) => {
//       let params = {
//         ACL: "private",
//         Bucket: "sageacedemy-media-uk",
//         Key: media.key,
//         ContentType: media.file.type,
//       };

//       if (media.waterMarkedUrl) {
//         const base64Data = new Buffer.from(
//           media.waterMarkedUrl.replace(/^data:image\/\w+;base64,/, ""),
//           "base64"
//         );
//         params.Body = base64Data;
//         params.ContentEncoding = "base64";
//       } else {
//         params.Body = media.file;
//       }

//       await myBucket.putObject(params).promise();
//     });

//     await Promise.all(uploadPromises);
//     console.log("All uploads successful");
//     return true; // All uploads successful
//   } catch (error) {
//     console.error("Error uploading images to S3:", error);
//     return false; // Error occurred during upload
//   }
// };

// export const deleteMediaFromS3Bucket = (key) => {
//   const params = {
//     Bucket: "sageacedemy-media-uk",
//     Key: key,
//   };

//   myBucket.deleteObject(params, (err, data) => {
//     if (err) {
//       console.error(`Error deleting object ${key}:`, err);
//     } else {
//       console.log(`Object ${key} deleted successfully`);
//     }
//   });
// };
