import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DELETE_STUDENT,
  GET_STUDENT,
  UPDATE_STUDENT,
} from "../../../constant/apiEndPoint";
import {
  deleteRequest,
  getRequest,
  patchRequest,
} from "../../../helper/axiosClient";
import { formatDate } from "../../../helper/dateFormate";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import useDebounce from "../../hook/useDebounce ";
import StudentAccordion from "./StudentAccordion";

const theadData = [
  { heading: "Id" },
  { heading: "Name" },
  { heading: "Email Address" },
  { heading: "Phone Number" },
  // { heading: "Course Name" },
  { heading: "Course Status" },
  { heading: "Status Update" },
  { heading: "Created Date" },
  { heading: "Action" },
];

const AllStudent = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalCompleteShow, setCompleteModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentDeleteId, setStudentDeleteId] = useState(null);
  const [studentDeleteCourseId, setStudentDeleteCourseId] = useState(null);
  const [studentDeleteUserId, setStudentDeleteUserId] = useState(null);
  const [studentUpdateData, setStudentUpdateData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const handleShowModal = (data) => {
    setModalShow(true);
    setStudentDeleteId(data._id);
    setStudentDeleteCourseId(data.courses.courseId._id);
    setStudentDeleteUserId(data.user);
  };
  const handleShowCompleteModel = (data) => {
    setCompleteModalShow(true);
    setStudentUpdateData(data);
  };

  const getStudentList = async (search = "") => {
    setIsLoading(true);
    try {
      // const params = { page: page, perPage: recordPerPage, search: search };
      const params = { search: search };
      const res = await getRequest(GET_STUDENT, params, navigate, dispatch);
      if (res.data.status === 1) {
        setStudentData(res.data.data);
        setTotalPage(res?.data.totalPage);
      }
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (studentDeleteId && studentDeleteCourseId && studentDeleteUserId) {
      try {
        const res = await deleteRequest(
          `${DELETE_STUDENT}?id=${studentDeleteId}&courseId=${studentDeleteCourseId}&userId=${studentDeleteUserId}`
        );
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          setActivePage(1);
          setPage(1);
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    }
    setModalShow(false);
    getStudentList();
  };

  const handleUpdateCourseStatus = async () => {
    if (studentUpdateData) {
      try {
        const res = await patchRequest(
          `${UPDATE_STUDENT}?id=${studentUpdateData._id}`,
          {
            ...studentUpdateData,
            courses: {
              ...studentUpdateData.courses,
              status: "Completed",
            },
          }
        );
        if (res.data.status === 1) {
          await getStudentList();
          successNotification(res?.response?.data?.message);
          setActivePage(1);
          setPage(1);
        }
        if (res.data.status === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    }
    setCompleteModalShow(false);
  };

  const handlePageChange = (pageIndex) => {
    setActivePage(pageIndex);
    setPage(pageIndex);
  };

  const handlePerPageChange = (e) => {
    setRecordPerPage(e);
  };

  const debouncedSearch = useDebounce((value) => {
    setPage(1);
    getStudentList(value.trim());
  }, 700);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim());
    debouncedSearch(value.trim());
  };

  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, recordPerPage]);

  const groupByCourse = (studentData) => {
    return studentData.reduce((result, student) => {
      const course = student.courseName;
      if (course) {
        if (!result[course]) {
          result[course] = [];
        }
        result[course].push(student);
      }
      return result;
    }, {});
  };

  const groupedData = groupByCourse(studentData);
  const courseNames = Object.keys(groupedData);
  const firstCourseName = courseNames.length > 0 ? courseNames[0] : null; // Check if courseNames has elements
  const [openAccordion, setOpenAccordion] = useState(firstCourseName);

  useEffect(() => {
    setOpenAccordion(firstCourseName);
  }, [firstCourseName]);

  const handleAccordionClick = (courseName) => {
    setOpenAccordion((prevOpenAccordion) =>
      prevOpenAccordion === courseName ? null : courseName
    );
  };

  return (
    <>
      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane eventKey="List" className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Students List</h4>
                  </div>
                  <div className="card-body">
                    <div
                      id="holidayList"
                      className="dataTables_wrapper no-footer"
                    >
                      <div className="justify-content-between d-sm-flex mb-2">
                        {/* <div className="dataTables_length">
                          <label className="d-flex align-items-center">
                            Show
                            <Dropdown className="search-drop">
                              <Dropdown.Toggle
                                as="div"
                                className="search-drop-btn"
                              >
                                {recordPerPage}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handlePerPageChange(10)}
                                >
                                  10
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handlePerPageChange(20)}
                                >
                                  20
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handlePerPageChange(30)}
                                >
                                  30
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            entries
                          </label>
                        </div> */}
                        <div className="dataTables_filter ms-auto">
                          <label>
                            Search :{" "}
                            <input
                              type="search"
                              placeholder="Search student"
                              value={searchTerm}
                              onChange={handleSearchChange}
                            />
                          </label>
                        </div>
                      </div>
                      {isLoading ? (
                        <Loader />
                      ) : studentData.length !== 0 ? (
                        <>
                          <div className="accordion">
                            {courseNames.map((courseName) => (
                              <>
                                {console.log(
                                  openAccordion,
                                  courseName,
                                  openAccordion === courseName
                                )}
                                <StudentAccordion
                                  key={courseName}
                                  courseName={courseName}
                                  students={groupedData[courseName]}
                                  isOpen={openAccordion === courseName}
                                  onClick={() =>
                                    handleAccordionClick(courseName)
                                  }
                                  theadData={theadData}
                                  handleShowCompleteModel={
                                    handleShowCompleteModel
                                  }
                                  handleShowModal={handleShowModal}
                                />
                              </>
                            ))}
                          </div>
                        </>
                      ) : (
                        <h4 className="text-center">No Student list found</h4>
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <CustomModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setStudentDeleteId(null);
            }}
            size="md"
            content={
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm delete
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete Student?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </Modal.Footer>
              </>
            }
          />
          <CustomModal
            show={modalCompleteShow}
            onHide={() => {
              setCompleteModalShow(false);
              setStudentUpdateData(null);
            }}
            size="md"
            content={
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm Completed
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to complete this course Student?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setCompleteModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => handleUpdateCourseStatus()}
                  >
                    Update
                  </button>
                </Modal.Footer>
              </>
            }
          />
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllStudent;
